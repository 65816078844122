








import { Component, Vue } from 'vue-property-decorator'
import { Routes } from '@/shared/router'

@Component
export default class NotFound extends Vue {
  goToHome() {
    this.$router.push({ name: Routes.app })
  }
}
